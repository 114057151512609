
import React from "react";
import ReactQuill from "react-quill";
import Quill from "quill"
import 'react-quill/dist/quill.snow.css';
import { RichTextEditorProps } from "./types";
import Grid from '@material-ui/core/Grid';
import { useStyles } from '../styles'
import { InputLabel } from "@material-ui/core";
import '../RenderFields.scss';
import htmlEditButton from "quill-html-edit-button";

const RichTextEditor: React.FC<RichTextEditorProps> = ({ id, type, label, value, allowHeadings, onChange, className, formatClassName, isMandatory, editorRef, readOnly,isInitialEditorLoad, setIsInitialEditorLoad }) => {
    const classes = useStyles();

    // Added to change auto added <p> tags into <div> tags
    var Block = Quill.import("blots/block")
    Block.tagName = "DIV"
    Quill.register(Block, true)

    // Added to support HTML editing
    Quill.register("modules/htmlEditButton", htmlEditButton);

    const toolbar: any = [];
    if (allowHeadings)
      toolbar.push([{'header': [1, false]}]); 
      toolbar.push(['bold', 'italic', 'underline'],[{'list': 'bullet'}],['link', 'image']); 
    return (
        <Grid item xs={12} className={classes.richTextEditorContainer} >
            <InputLabel shrink className={classes.editText}>
                {label}<span className={isMandatory ? classes.isMandatory : classes.hide } > *</span>
            </InputLabel>
            <ReactQuill 
                id={id}
                value={value} 
                onChange={(newValue:string, delta:any, source:string) => {
                  if (isInitialEditorLoad) {
                    setIsInitialEditorLoad && setIsInitialEditorLoad(false);
                  } else {
                    onChange(source, type, newValue)
                  }                  
                }}
                className={`${className ? className : classes.richTextEditor} ${formatClassName ? formatClassName : ''} `}
                modules={{
                  clipboard: {
                    matchVisual: false,
                  },
                  toolbar,
                  htmlEditButton: {
                    msg: "Edit the content in HTML format (inline styles are not allowed)", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                    okText: "Ok", // Text to display in the OK button, default: Ok,
                    cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
                    buttonHTML: "HTML", // Text to display in the toolbar button, default: <>
                    buttonTitle: "Show HTML source (inline styles are not allowed)", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                    prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                    editorModules: {} // The default mod
                  }
                }}
                bounds={`#${id}`}
                ref={editorRef}
                readOnly={readOnly || false}
            />
        </Grid>
      );
};

export default RichTextEditor;
