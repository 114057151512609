export enum FormatClassNames {
    APP_POPUP = 'app-popup',
    LOGIN_FORM = 'login-form'
}

export interface RichTextEditorProps {
    id: string,
    type: string,
    label: string,
    value: string,
    onChange: any,
    allowHeadings?: boolean
    className?: any
    formatClassName?: FormatClassNames
    isMandatory?: boolean,
    editorRef?: any,
    readOnly?: boolean,
    isInitialEditorLoad?: boolean,
    setIsInitialEditorLoad?: Function
}
