import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { TranslationRowProps } from './types';
import parse from 'html-react-parser';

const TranslationRow: React.FC<TranslationRowProps> = ({ clientId, languageTranslation, language, useGrayBackground, onChange, checked, rowContainerClass, showCheckbox, status }) => {
    const classes = useStyles();
    const [expandRow, setExpandRow] = useState(false);

    const handleRowClick = () => {
        setExpandRow(!expandRow)
    }

    const backGroundClass = useGrayBackground ? classes.graySoft : '';

    return (
        <Grid container key={`row_${languageTranslation.language}`} className={`${backGroundClass} ${rowContainerClass}`}>
            <Grid container className={classes.translationRow}>
                <Grid item className={showCheckbox ? classes.listGridSelectLanguage : classes.listGridSelectLanguageShowPublish}>
                    {showCheckbox ? (
                        <CheckboxWithName
                            name={languageTranslation.language}
                            id={`${clientId}_${languageTranslation.language}`}
                            value={language}
                            defaultValue={language}
                            checked={checked}
                            onClick={(event: any) => onChange(event.target)}
                            label=""
                            className={classes.checkboxField}
                        />
                    ) : (
                        <span className={classes.onlyLabel}>{language}</span>
                    )}

                </Grid>
                <Grid item className={`${classes.translationText} ${classes.translationRowApplicationName}`} onClick={handleRowClick}>
                    {languageTranslation.name}
                </Grid>
                <Grid className={`${!showCheckbox ? classes.show : classes.hide} ${classes.statusRow}`}>
                    {status}
                </Grid>
                <Grid item className={!showCheckbox ? classes.translationRowIconShowPublish : classes.translationRowIcon} onClick={handleRowClick}>
                    <img src={'/images/icons/ChevronDown.svg'} className={expandRow ? classes.iconStyleRotate : classes.iconStyle} alt="" />
                </Grid>
            </Grid>
            {expandRow && (
                <Grid container>
                    {languageTranslation.description !== '' && languageTranslation.description !== '<p><br></p>' && (
                        <Grid container className={classes.translationDetailsSubRow}>
                            <Grid item className={classes.translationDetailsItemDescription}>
                                Application description:
                            </Grid>
                            <Grid item className={classes.translationDetailsItem}>
                                {parse(languageTranslation.description || '')}
                            </Grid>
                        </Grid>
                    )}
                    {languageTranslation.login_text !== '' && languageTranslation.login_text !== '<p><br></p>' && (
                        <Grid container className={classes.translationDetailsSubRow}>
                            <Grid item className={classes.translationDetailsItemDescription}>
                                Application login text:
                            </Grid>
                            <Grid item className={classes.translationDetailsItem}>
                                {parse(languageTranslation.login_text || '')}
                            </Grid>
                        </Grid>
                    )}
                    {languageTranslation.signup_text !== '' && languageTranslation.signup_text !== '<p><br></p>' && (
                        <Grid container className={classes.translationDetailsSubRow}>
                            <Grid item className={classes.translationDetailsItemDescription}>
                                Application sign up text:
                            </Grid>
                            <Grid item className={classes.translationDetailsItem}>
                                {parse(languageTranslation.signup_text || '')}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export default TranslationRow;
