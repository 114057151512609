
import React from 'react';
import { Field } from 'redux-form';
import { ImportedApplicationTranslations, LanguageTranslation } from '../../../API/application';
import { LabeledSelectbox, LabeledTextbox } from '../../RenderFields/RenderFields';
import { languages } from '../../../Constants';
import { ApplicationOption } from './types';
import RichTextEditor from '../../RenderFields/RichTextEditor';
import { FormatClassNames } from '../../RenderFields/RichTextEditor/types';

export const validateName = (value: string, profile: any, props: any, name: string) => {

    const appTranslations = profile?.applicationTranslations || props?.initialValues?.applicationTranslations

    if (!appTranslations || !profile?.application || !profile?.language || !profile?.name) return null;

    let transFound = false;

    // Check if we have other applications with the same language translation of the name 
    appTranslations.filter((app: ImportedApplicationTranslations) => app.client_id !== profile.application).map((appTrans: ImportedApplicationTranslations) => {
        appTrans.application_texts.map(trans => {
            if (trans.language === profile.language && (trans.name || '') === profile.name) {
                transFound = true;
            }

            return null;
        })

        return null;
    })

    if (transFound && (profile.name !== '')) {
        return "Name already in use";
    }

    return undefined;
}

export const renderNameField = (languageTranslations: LanguageTranslation, selectedApplication: string, classes: any, handleChange: Function) => {
    return (<Field
        id="name"
        name="name"
        label="Application name"
        value={languageTranslations.name || ''}
        defaultValue={languageTranslations.name || ''}
        onChange={({ target }: { target: HTMLInputElement }) => {
            handleChange('user', 'name', target.value);
        }}
        placeholder="Application name"
        component={LabeledTextbox}
        InputProps={{
            classes: {
                input: classes.blackText
            }
        }}
        disabled={!selectedApplication}
        validate={validateName}
    />
    )
}

export const renderLanguageField = (selectedLanguage: string, handleLanguageChange: Function) => {
    return (
        <Field
            id="language"
            name="language"
            label="Language"
            value={selectedLanguage}
            defaultValue={selectedLanguage}
            onChange={({ target }: { target: HTMLInputElement }) => {
                handleLanguageChange(target.value);
            }}
            placeholder={"Language"}
            component={LabeledSelectbox}
            options={languages.sort((lan1, lan2) => lan1.text.localeCompare(lan2.text))}
        />
    )
}

export const renderApplicationField = (selectedApplication: string, userApplications: ApplicationOption[], handleApplicationChange: Function) => {
    return (
        <Field
            id="application"
            name="application"
            label="Application"
            value={selectedApplication}
            defaultValue={selectedApplication}
            onChange={({ target }: { target: HTMLInputElement }) => {
                handleApplicationChange(target.value);
            }}
            placeholder={"Application"}
            component={LabeledSelectbox}
            options={userApplications}
        />
    )
}

export const renderAppDescriptionField = (languageTranslations: LanguageTranslation, selectedApplication: string, classes: any, handleChange: Function, isInitialEditorLoad: boolean, setIsInitialEditorLoad: Function) => {
    return (
        <RichTextEditor
            id='appdescription'
            type="description"
            label="Application description"
            value={languageTranslations.description || ''}
            onChange={handleChange}
            className={classes.richTextEditor}
            formatClassName={FormatClassNames.APP_POPUP}
            readOnly={!selectedApplication}
            isInitialEditorLoad={isInitialEditorLoad}
            setIsInitialEditorLoad={setIsInitialEditorLoad}
        />
    )
}

export const renderLoginTextField = (languageTranslations: LanguageTranslation, selectedApplication: string, classes: any, loginTextEditorRef: React.MutableRefObject<null>, handleChange: Function, isInitialEditorLoad: boolean, setIsInitialEditorLoad: Function) => {
    return (
        <RichTextEditor
            id="logintext"
            type="loginText"
            label="Application login text"
            value={languageTranslations.login_text || ''}
            onChange={handleChange}
            className={classes.richTextEditor}
            formatClassName={FormatClassNames.LOGIN_FORM}
            allowHeadings={true}
            editorRef={loginTextEditorRef}
            readOnly={!selectedApplication}
            isInitialEditorLoad={isInitialEditorLoad}
            setIsInitialEditorLoad={setIsInitialEditorLoad}
        />
    )
}

export const renderSignupTextField = (languageTranslations: LanguageTranslation, selectedApplication: string, classes: any, signupTextEditorRef: React.MutableRefObject<null>, handleChange: Function, isInitialEditorLoad: boolean, setIsInitialEditorLoad: Function) => {
    return (
        <RichTextEditor
            id="signuptext"
            type="signupText"
            label="Application signup text"
            value={languageTranslations.signup_text || ''}
            onChange={handleChange}
            className={classes.richTextEditor}
            formatClassName={FormatClassNames.LOGIN_FORM}
            allowHeadings={true}
            editorRef={signupTextEditorRef}
            readOnly={!selectedApplication}
            isInitialEditorLoad={isInitialEditorLoad}
            setIsInitialEditorLoad={setIsInitialEditorLoad}
        />
    )
}