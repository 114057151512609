import React, { useState, useRef, useEffect } from 'react';
import Main from '../../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { reduxForm, reset, InjectedFormProps } from 'redux-form';
import { languages } from '../../../Constants';
import { useSelector, useDispatch, connect } from 'react-redux';
import { AppState } from '../../../Store';
import { ApplicationOption, AppTranslationProps } from './types';
import { getApplicationTranslations, updateApplicationTranslations, ApplicationTranslations, LanguageTranslation, ImportedApplicationTranslations, getAllApplicationTranslations } from '../../../API/application';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import Button from '@material-ui/core/Button';
import { Application, getApplicationsForUserWithRoles } from '../../../API/application';
import { useAuth0 } from '@auth0/auth0-react';
// @ts-ignore
import * as XLSX from 'xlsx';
import Download from '../../Download';
import './styles.scss';
import { Roles } from '../../../Store/Roles/types';
import settings from '../../../Config/settings';
import ImportConfirmPopup from '../../ImportPopup/ImportConfirmPopup';
import { ImportProgress, ApplicationErrors, ApplicationWarnings, XlsxEncryptionError, XlsxParsingError } from '../../ImportPopup/types';
import ImportWarningsPopup from '../../ImportPopup/ImportWarningsPopup';
import DataLoader from '../../DataLoader';
import { validateImportedData } from './validation';
import ImportErrorsPopup from '../../ImportPopup/ImportErrorsPopup';
import DOMPurify from 'dompurify';
import queryString from 'query-string';
import { Spinner } from '@danfoss/webex-ui';
import ModalPopup from '../../Modal/ModalPopup';
import LoginPopup from './LoginPopup';
import SignupPopup from './SignupPopup';
import ApplicationPopup from './ApplicationPopup';
import { getDanfossIdentityId, getUserRoles } from '../../../Utils';
import { renderNameField, renderLanguageField, renderApplicationField, renderAppDescriptionField, renderLoginTextField, renderSignupTextField } from './fieldRenderer'
import { SetApplicationTranslationsAction } from '../../../Store/Applications/actions';

function asyncValidate(values: any, dispatch: any, props: any) {
	return new Promise((resolve, reject) => {
		const errors = validate(values, props);

		if (Object.keys(errors).length === 0) {
			resolve(true);
		}
		reject(errors);
	});
}

const validate = (values: any, props: any) => {

	const parsed = queryString.parse(window.location.search);
	const client_id = (parsed && parsed.client_id) as string

	let errors = {};

	if (!values.application && !client_id) {
		errors = { ...errors, application: "has errors" };
	}

	return errors;
};

const AppTranslation: React.FC<InjectedFormProps<{}> & AppTranslationProps> = ({ change, resetForm, allApplicationTranslations, setLeaveConfirmValues }) => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { getAccessTokenSilently } = useAuth0();
	const currentUser = useSelector((state: AppState) => state.user);
	const applications = useSelector((state: AppState) => state.applications && state.applications.applications);
	const applicationsLoaded = useSelector((state: AppState) => state.applications.applicationsLoadingStatus.loaded);
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));

	const [selectedLanguage, setSelectedLanguage] = useState("en");
	const [selectedApplication, setSelectedApplication] = useState("");
	const [isInitialEditorLoad, setIsInitialEditorLoad] = useState(true);
	const [applicationTranslations, setApplicationTranslations] = useState({} as ApplicationTranslations);
	const [languageTranslations, setLanguageTranslations] = useState({} as LanguageTranslation);
	const [userApplications, setUserApplications] = useState([] as ApplicationOption[]);
	const [auth0AppName, setAuth0AppName] = useState("");
	const [isDataChanged, setIsDataChanged] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [showSignupForm, setShowSignupForm] = useState(false);
	const [showApplicationForm, setShowApplicationForm] = useState(false);
	const [importedData, setImportedData] = useState({} as ImportedApplicationTranslations)
	const [importTypeProgress, setImportTypeProgress] = useState(ImportProgress.NotStarted);
	const [importedAppTranslation, setImportedAppTranslation] = useState({ errors: [], generalError: '', warnings: [] } as { errors: ApplicationErrors[], generalError?: string, warnings: ApplicationWarnings[] });
	const [standardLoginText, setStandardLoginText] = useState('');
	const standardSignupText = "<span style=\"font-size: 20px;font-weight: bold;\">Benefits of a Danfoss Profile</span><br/><ul><li>Easy access to a variety of Danfoss applications</li><li>Single username and password</li><li>Maintain your information in one place</li><li>Overview of your Danfoss applications</li></ul>We are not done including all our applications. You will soon have access to more of your preferred applications using the Danfoss Profile.";
	//Get querystring parameters
	const parsed = queryString.parse(window.location.search);
	const client_id = (parsed && parsed.client_id) as string

	const loginTextEditorRef = useRef(null);
	const signupTextEditorRef = useRef(null);

	// Added to prevent DOMPurify from stripping target="_blank" from links
	DOMPurify.addHook('afterSanitizeAttributes', function (node) {
		// set all elements owning target to target=_blank
		if ('target' in node) {
			node.setAttribute('target', '_blank');
		}
	});

	useEffect(() => {

		const getUserApps = async () => {

			setIsLoading(true);

			if (currentUser.userLoaded && currentUser.user && applicationsLoaded) {
				const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
				const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);

				let clientIdsForUser = [] as string[];

				if (userRoles.some(role => role === Roles.DASHBOARD_ADMIN)) {
					clientIdsForUser = applications?.map(app => app.client_id) || [];
				}
				else {
					clientIdsForUser = await getApplicationsForUserWithRoles(accessTokenMyDanfossApi, accessTokenMyDanfossAccountApi, getDanfossIdentityId(currentUser), [Roles.APPLICATION_OWNER, Roles.APPLICATION_EDITOR]);
				}

				const appsForUser = applications?.filter(app => clientIdsForUser.includes(app.client_id)) || [];

				setUserApplications(
					appsForUser.sort((app1, app2) => app1.name.localeCompare(app2.name)).map(app => {
						return { id: app.client_id, text: app.name }
					})
				);

			}

			if (client_id) {
				change("application", client_id);
				handleApplicationChange(client_id);
			}
		}

		getUserApps().then(resp => {
			setIsLoading(false);
		});

	}, [currentUser, userRoles, applications, applicationsLoaded]);

	const updateStandardLoginText = (appName: string) => {
		setStandardLoginText(`Use your Danfoss Profile to log into ${appName}.<br/><br/> If you have not already made a Danfoss Profile, please use the signup button above to create one. The Danfoss Profile can be used for all Danfoss applications where you see this login screen.`)
	}

	const handleLanguageChange = (language: string) => {
		if (isDataChanged) {
			setIsDataChanged(false);
		}

		setSelectedLanguage(language);
		const hasDraft = ((applicationTranslations.application_texts || []).filter((appTranslation: LanguageTranslation) => appTranslation.language === language && !appTranslation.published) || {} as LanguageTranslation).length > 0;
		const lanChange = (applicationTranslations.application_texts || []).find((appTranslation: LanguageTranslation) => appTranslation.language === language && appTranslation.published === !hasDraft) || {} as LanguageTranslation;

		change("name", '');
		setLanguageTranslations(lanChange);
		updateStandardLoginText(lanChange.name || auth0AppName);
		setLeaveConfirmValues(lanChange, lanChange, false, resetForm, document.getElementById('formSubmitButton'))
	}

	const handleApplicationChange = (client_id: string) => {
		handleResetClick();

		if (isDataChanged && selectedApplication !== '') {
			setIsDataChanged(false);
		}

		let appTranslations = {} as ApplicationTranslations;

		const getAppTranslations = async () => {
			appTranslations = await getApplicationTranslations(client_id);

			setSelectedApplication(client_id);
			setAuth0AppName(applications?.find(app => app.client_id === client_id)?.name || "");
			setApplicationTranslations(appTranslations)

			// Check if the application is in draft
			const hasDraft = ((appTranslations.application_texts || []).filter((appTranslation: LanguageTranslation) => appTranslation.language === selectedLanguage && !appTranslation.published) || {} as LanguageTranslation).length > 0;
			const lanChange = (appTranslations.application_texts || []).find((appTranslation: LanguageTranslation) => appTranslation.language === selectedLanguage && appTranslation.published === !hasDraft) || {} as LanguageTranslation;

			change("name", '');
			setLanguageTranslations(lanChange);

			if (Object.keys(lanChange).length === 0) {
				setIsInitialEditorLoad(false);
			}

			updateStandardLoginText(lanChange.name || auth0AppName);
			setLeaveConfirmValues(lanChange, lanChange, false, resetForm, document.getElementById('formSubmitButton'))
		}

		getAppTranslations();
	}

	const handleChange = (source: string, type: string, value: string) => {
		let newTranslationsOld = { ...languageTranslations };
		let newTranslations = { ...languageTranslations };
		
		if (value !== "<div><br></div>") {
			switch (type) {
				case 'name':
					newTranslations.name = value;
					break;
				case 'description':
					newTranslations.description = value;
					break;
				case 'loginText':
					newTranslations.login_text = value;
					break;
				case 'signupText':
					newTranslations.signup_text = value;
					break;
			}
			setLanguageTranslations({ ...newTranslations, language: selectedLanguage, published: false });
			setLeaveConfirmValues(newTranslationsOld, { ...newTranslations, language: selectedLanguage, published: false }, true, resetForm, document.getElementById('formSubmitButton'))
			setIsDataChanged(true);
		}else if (source === 'api') {
		 	setIsDataChanged(true);
		}

	}

	const stripEmptyHtml = (html: string) => {
		const doc = new DOMParser().parseFromString(html, "text/html");
		return doc.body.innerText.trim() !== '' && html || '';
	}

	const handleSubmitForm = (e?: React.FormEvent<HTMLFormElement>) => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		setIsSaving(true);

		let newAppTranslations = [] as LanguageTranslation[];

		if ((applicationTranslations.application_texts || []).filter(appTrans => !appTrans.published).find(appTrans => appTrans.language === selectedLanguage)) {
			// merge current languageTranslations into application translation object
			newAppTranslations = applicationTranslations.application_texts.filter(appTrans => !appTrans.published).map((appTrans: LanguageTranslation) => {
				if (appTrans.language === selectedLanguage) {
					return languageTranslations
				}

				return appTrans
			})
		} else {
			newAppTranslations = [...(applicationTranslations.application_texts || []), languageTranslations]
		}

		const updateTranslations = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			await updateApplicationTranslations(selectedApplication, {
				application_texts: newAppTranslations.filter(appTrans => !appTrans.published)
					.map(appTrans => ({
						...appTrans,
						description: appTrans.description,
						login_text: appTrans.login_text,
						signup_text: appTrans.signup_text
					}))
			}, accessTokenMyDanfossApi);

			setApplicationTranslations({ application_texts: newAppTranslations })
			setLeaveConfirmValues({ application_texts: newAppTranslations }, { application_texts: newAppTranslations }, false, resetForm, document.getElementById('formSubmitButton'))
			setIsDataChanged(false);
			setIsSaving(false);

			const appTranslations = await getAllApplicationTranslations();
			dispatch(SetApplicationTranslationsAction(appTranslations.application_text));
		}

		updateTranslations();

	}

	const getDataItems = () => {
		const englishTranslations = (applicationTranslations.application_texts || []).find((appTranslation: LanguageTranslation) => appTranslation.language === 'en') || {} as LanguageTranslation

		let dataset: any[] = [];

		languages.forEach(language => {

			const languageTranslations = (applicationTranslations.application_texts || []).find((appTranslation: LanguageTranslation) => appTranslation.language === language.id) || {} as LanguageTranslation

			dataset.push(
				{
					sheetName: language.id,
					dataSet: {
						columns: [{ title: "Description" }, { title: "English" }, { title: language.text }],
						data: [
							[{ value: "Application name" }, { value: englishTranslations.name }, { value: languageTranslations.name || '' }],
							[{ value: "Application description" }, { value: englishTranslations.description }, { value: languageTranslations.description || '' }],
							[{ value: "Application login text" }, { value: englishTranslations.login_text }, { value: languageTranslations.login_text || '' }],
							[{ value: "Application signup text" }, { value: englishTranslations.signup_text }, { value: languageTranslations.signup_text || '' }],
						]
					}
				}
			);
		});

		return dataset;
	}

	const handleResetClick = () => {
		dispatch(reset('appTranslation'));
		setSelectedApplication("");
		setLanguageTranslations({} as LanguageTranslation);
		setSelectedLanguage("en");
	}

	const handlePublishClick = () => {
		return navigate(`/publish/applicationtranslation?client_id=${selectedApplication}`);
	}

	const handleImportClick = (e: any) => {
		setIsSaving(true);
		setImportTypeProgress(ImportProgress.ConfirmImport);

		const files = e.target.files, f = files[0];
		const reader = new FileReader();
		const appData = { client_id: selectedApplication, application_texts: [] } as ImportedApplicationTranslations

		reader.onload = function (e) {
			const data = e.target?.result;
			try {
				const readData = XLSX.read(data, { type: 'binary' });

				readData.SheetNames.forEach((sheet) => {

					// Read sheetdata based on sheetname
					const sheetData = readData.Sheets[sheet];

					// Convert exceldata to json
					const sheetDataAsJson = XLSX.utils.sheet_to_json(sheetData, { header: 1 });

					// Get the different texts
					const name = sheetDataAsJson[1] as string[];
					const description = sheetDataAsJson[2] as string[];
					const login_text = sheetDataAsJson[3] as string[];
					const signup_text = sheetDataAsJson[4] as string[];

					// add imported data
					if (name[2] !== '' || description[2] !== '' || login_text[2] !== '' || signup_text[2] !== '') {
						appData.application_texts.push({ language: sheet, name: DOMPurify.sanitize(name[2]), description: DOMPurify.sanitize(description[2]), login_text: DOMPurify.sanitize(login_text[2]), signup_text: DOMPurify.sanitize(signup_text[2]), published: false });
					}
				});

				setImportedData(appData);

				const { errors, warnings } = validateImportedData(appData, applicationTranslations, userApplications, applications || [] as Application[]);

				if (errors.length > 0) {
					console.log('Errors', errors);
				}

				if (warnings.length > 0) {
					console.log('Warnings', warnings);
				}

				setImportTypeProgress((errors.length > 0 && ImportProgress.HandleErrors) || (warnings.length > 0 && ImportProgress.HandleWarnings) || ImportProgress.ConfirmImport)
				setImportedAppTranslation({ errors, warnings })
			}
			catch (error: unknown) {
				let generalError = '';
				if ((error as Error).message.includes('Encrypted file missing /EncryptionInfo')) {
					generalError = XlsxEncryptionError;
				}
				else {
					generalError = XlsxParsingError.replace('{error-message}', (error as Error).message);
				}
				setImportTypeProgress(ImportProgress.HandleErrors);
				setImportedAppTranslation({
					errors: [],
					generalError
					, warnings: []
				})
			}
		};

		e.target.value = '';

		reader.readAsBinaryString(f)
	}

	const cancelImport = () => {
		setImportedAppTranslation({ errors: [], warnings: [] });
		setImportTypeProgress(ImportProgress.NotStarted);
		setImportedData({} as ImportedApplicationTranslations);
		setIsSaving(false);
	}

	const continueImport = () => {
		const updateTranslations = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			await updateApplicationTranslations(selectedApplication, importedData, accessTokenMyDanfossApi);

			setApplicationTranslations(importedData)
			setIsDataChanged(false);
			setIsSaving(false);
			handleApplicationChange(selectedApplication);
		}

		updateTranslations().then(resp => {
			setImportTypeProgress(ImportProgress.NotStarted);
		});
	}

	const handleUndoChangesClick = (id: string) => {
		return navigate(`/application/undochanges?id=${id}`);
	}

	const handleOverviewClick = () => {
		return navigate(`/application/translation/overview?client_id=${selectedApplication}`);
	}

	const validateForm = () => {
		if (selectedApplication === "") {
			return true;
		}

		if (selectedLanguage === "") {
			return true;
		}

		if (!isDataChanged) {
			return true;
		}

		if ((languageTranslations?.name || "").trim() === "" && (languageTranslations?.description || "").replace("<div><br></div>", "").trim() === "" && (languageTranslations?.login_text || "").replace("<div><br></div>", "").trim() === "" && (languageTranslations?.signup_text || "").replace("<div><br></div>", "").trim() === "") {
			return true;
		}

		let transFound = false;

		// Check if we have other applications with the same language translation of the name 
		allApplicationTranslations && allApplicationTranslations.filter(app => app.client_id !== selectedApplication).map(appTrans => {
			appTrans.application_texts.map(trans => {
				if (trans.language === selectedLanguage && (trans.name || '') === (languageTranslations?.name || '') && (trans.name || '') !== '')  {
					transFound = true;
				}

				return null;
			})

			return null;
		})

		if (transFound) {
			return true;
		}

		return false;
	}

	const setDefaultText = (type: string, appName: string) => {
		if (type === 'login') {
			const quillEditorLogin = (loginTextEditorRef.current || {} as any).getEditor();
			quillEditorLogin.root.innerHTML = standardLoginText;
		} else {
			const quillEditorSignup = (signupTextEditorRef.current || {} as any).getEditor();
			quillEditorSignup.root.innerHTML = standardSignupText;
		}
	}

	if (showLoginForm || showSignupForm || showApplicationForm) {
		(document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	return (
		<Main breadCrumbs={{ items: [{ text: `Application translation`, link: location.pathname }] }}>
			{showLoginForm && (
				<ModalPopup paperClassName={classes.bannerPopupLogin}>
					<LoginPopup
						setShowLoginForm={setShowLoginForm}
						text={(languageTranslations.login_text !== '<div><br></div>' && languageTranslations.login_text !== '') ? languageTranslations.login_text : standardLoginText}
						header="Log in or sign up for a Danfoss Profile"
						applicationName={languageTranslations.name}
						auth0ApplicationName={auth0AppName}
					/>
				</ModalPopup>
			)}
			{showSignupForm && (
				<ModalPopup paperClassName={classes.bannerPopupSignup}>
					<SignupPopup
						setShowSignupForm={setShowSignupForm}
						text={(languageTranslations.signup_text !== '<div><br></div>' && languageTranslations.signup_text !== '') ? languageTranslations.signup_text : standardSignupText}
						header="Sign up for a Danfoss Profile"
					/>
				</ModalPopup>
			)}
			{showApplicationForm && (
				<ModalPopup paperClassName={classes.bannerPopupApplication}>
					<ApplicationPopup
						setShowApplicationForm={setShowApplicationForm}
						description={languageTranslations.description || ''}
						name={languageTranslations.name || ''}
						client_id={selectedApplication}
						dip_app_type={applications?.find(app => app.client_id === selectedApplication)?.dip_app_type || ''}
					/>
				</ModalPopup>
			)}
			<DataLoader applicationsRequired={true} >
				{isLoading ? (<Spinner visible={isLoading} />) : (
					<Grid container>
						<ImportErrorsPopup importTypeProgress={importTypeProgress} errors={importedAppTranslation.errors} generalError={importedAppTranslation.generalError} onCancelImport={cancelImport} />
						<ImportWarningsPopup importTypeProgress={importTypeProgress} warnings={importedAppTranslation.warnings} onCancelImport={cancelImport} onContinueImport={continueImport} importButtonTypeText="Import translations" showOtherFilesButton={false} />
						<ImportConfirmPopup importTypeProgress={importTypeProgress} importItemNames={[(userApplications.find(app => app.id === selectedApplication) || {}).text || '']} importTypeText="app translations" importButtonTypeText="translations" onCancelImport={cancelImport} onPerformImport={continueImport} />
						<Grid container className={classes.formContainer}>

							<form onSubmit={handleSubmitForm} id="appTranslation" className={classes.translationForm}>
								<Grid container id="clickoutsideContainer">
									{renderApplicationField(selectedApplication, userApplications, handleApplicationChange)}
									{renderLanguageField(selectedLanguage, handleLanguageChange)}
									{renderNameField(languageTranslations, selectedApplication, classes, handleChange)}
									<Grid container>
										<Grid item className={classes.editorContainer}>
											{renderAppDescriptionField(languageTranslations, selectedApplication, classes, handleChange, isInitialEditorLoad, setIsInitialEditorLoad)}
										</Grid>
										<Grid item>
											<Button
												onClick={() => setShowApplicationForm(true)}
												type="button"
												variant="outlined"
												color="default"
												className={classes.buttonPreview}
												disabled={(languageTranslations.description || '') === ''}
											>
												Preview
											</Button>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item className={classes.editorContainer}>
											{renderLoginTextField(languageTranslations, selectedApplication, classes, loginTextEditorRef, handleChange, isInitialEditorLoad, setIsInitialEditorLoad)}
										</Grid>
										<Grid item className={classes.textAreaButtonContainer}>
											<Button
												onClick={() => setShowLoginForm(true)}
												type="button"
												variant="outlined"
												color="default"
												className={classes.buttonPreview}
												disabled={!selectedApplication || !languageTranslations.login_text}
											>
												Preview
											</Button>
											<Button
												onClick={() => setDefaultText("login", languageTranslations.name || auth0AppName)}
												type="button"
												variant="outlined"
												color="default"
												className={classes.buttonDefault}
												disabled={!selectedApplication}
											>
												Default
											</Button>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item className={classes.editorContainer}>
											{renderSignupTextField(languageTranslations, selectedApplication, classes, signupTextEditorRef, handleChange, isInitialEditorLoad, setIsInitialEditorLoad)}
										</Grid>
										<Grid item className={classes.textAreaButtonContainer}>
											<Button
												onClick={() => setShowSignupForm(true)}
												type="button"
												variant="outlined"
												color="default"
												className={classes.buttonPreview}
												disabled={!selectedApplication || !languageTranslations.signup_text}
											>
												Preview
											</Button>
											<Button
												onClick={() => setDefaultText("signup", languageTranslations.name || auth0AppName)}
												type="button"
												variant="outlined"
												color="default"
												className={classes.buttonDefault}
												disabled={!selectedApplication}
											>
												Default
											</Button>
										</Grid>
									</Grid>
									<Grid id="buttonContainer" className={classes.buttonContainer}>
										<SpinnerButton
											type="submit"
											variant="contained"
											color="primary"
											className={classes.button}
											pathToImagesFolder={'/images/icons'}
											spinnerVisible={isSaving}
											disabled={validateForm()}
											id="formSubmitButton"
										>
											Save
										</SpinnerButton>
										<Button
											onClick={() => handleResetClick()}
											type="button"
											variant="outlined"
											color="default"
											className={classes.button}
											disabled={selectedApplication === ""}
										>
											Reset
										</Button>
										<Download getDataItems={getDataItems} disabled={selectedApplication === ''} fileName={auth0AppName} />
										<label htmlFor="files" className={selectedApplication ? classes.buttonLabel : classes.buttonLabelGray}>Import texts</label>
										<input id="files" className={classes.buttonHidden} type="file" onChange={(e) => handleImportClick(e)} disabled={selectedApplication === ''} accept=".xlsx" />
									</Grid>
								</Grid>
								{selectedApplication !== '' && (
									<Grid container spacing={5} className={classes.LinkContainer}>
										<Grid item>
											<span onClick={() => handleOverviewClick()} className={`${classes.linkText} triggerWarning`}>
												<img src={'/images/LinkArrow.svg'} className={classes.linkArrow} alt="" /> Overview
											</span>
										</Grid>
										{applicationTranslations?.application_texts?.filter(appTrans => !appTrans.published).length > 0 && (
											<Grid item>
												<span onClick={() => handleUndoChangesClick(selectedApplication)} className={`${classes.linkText} triggerWarning`}>
													<img src={'/images/LinkArrow.svg'} className={classes.linkArrow} alt="" /> View changes
												</span>
											</Grid>
										)}
										<Grid item>
											{userRoles && userRoles.some(userRole => [Roles.APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN].some(role => role === userRole)) &&
												applicationTranslations.application_texts?.some(appTrans => !appTrans.published) &&
												importTypeProgress === ImportProgress.NotStarted && (
													<span onClick={handlePublishClick} className={`${classes.linkText} triggerWarning`}>
														<img src={'/images/LinkArrow.svg'} className={classes.linkArrow} alt="" /> Publish
													</span>
												)}
										</Grid>
									</Grid>
								)}
							</form>
						</Grid>
					</Grid>
			)}
			</DataLoader>
		</Main>
	);
}

const mapDispatchToProps = (dispatch: any) => () => ({
	resetForm: () => dispatch(reset('appTranslation'))
});

const mapStateToProps = (state: AppState) => ({
	allApplicationTranslations: state.applications?.applicationTranslations
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AppTranslation)

const appTranslationForm = reduxForm({
	form: 'appTranslation',
	validate,
	asyncValidate,
	asyncBlurFields: [],
	asyncChangeFields: ['description', 'loginText', 'signupText']
})(connectedComponent);

export default connect((state: AppState) => ({
	initialValues: {
		applicationTranslations: state.applications?.applicationTranslations,
		language: state.form?.appTranslation?.values?.language || 'en',
		application: state.form?.appTranslation?.values?.application
	},
}))(appTranslationForm);

